import * as React from "react";
import { StyledSVG } from "./StyledSVG";

const IconPdfDocument = props => {
  const { fill, ...restProps } = props;
  const defaultPdfIconColor = "#2B6E33";

  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...restProps}>
      <path
        fill={fill || defaultPdfIconColor}
        d="M5.52 12.41c-.22 0-.37.02-.45.04v1.41c.09.02.21.03.36.03.57 0 .93-.29.93-.78 0-.44-.3-.7-.84-.7Zm4.18.01c-.24 0-.4.02-.49.04v3.13c.09.02.24.02.38.02.98 0 1.62-.53 1.62-1.68 0-1-.57-1.52-1.51-1.52v.01Z"
      />
      <path
        fill={fill || defaultPdfIconColor}
        d="M12 0H4C2.9 0 2 .9 2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6l-6-6ZM7 14.22c-.37.35-.92.5-1.56.5-.12 0-.25 0-.37-.02v1.71H4v-4.72c.48-.07.97-.11 1.46-.1.67 0 1.14.13 1.46.38.3.24.51.64.51 1.11 0 .47-.16.87-.44 1.14H7Zm4.57 1.63c-.5.42-1.27.62-2.21.62-.56 0-.96-.04-1.23-.07v-4.7c.48-.07.97-.1 1.46-.1.91 0 1.5.16 1.96.51.5.37.81.96.81 1.8 0 .92-.33 1.55-.8 1.94h.01ZM16 12.52h-1.84v1.09h1.72v.88h-1.72v1.92h-1.09v-4.79H16v.9ZM12 7h-1V2l5 5h-4Z"
      />
    </StyledSVG>
  );
};

export default IconPdfDocument;
