import React from "react";
import MainLayout from "../componentsTailwind/MainLayout";
import { Helmet } from "react-helmet";
import MainVizualLP from "../components/MainVizualLP";
import SectionGrid from "../components/SectionGrid/SectionGrid";
import PhoneContactFormWrapper from "../components/LeadForms/PhoneContactFormWrapper";
import ContactForm from "../components/contactForm";
import { CONTRACT_SOURCE_ID_ZAME } from "../constants";
import { graphql } from "gatsby";
import { getFullMediaLibraryURL } from "../helpers/mediaLibrary/mediaLibraryUtils";
import { SavingsCalculation } from "../components/SavingsCalculation/SavingsCalculation";
import { CALCULATION_COMMODITY_INDEX } from "../components/SavingsCalculation/hooks/use-calculation";
import { useCalculationResultChange } from "../components/SavingsCalculation/hooks/use-calculation-result-change";
import { formatPrice } from "../formUtils";
import { Button } from "../style/Button";
import { MarkupToHtml } from "../helpers/parse";

const BONUS_TEXT_KEY = "BONUS_AMOUNT";
const ZAME_ANALYTICS = "zamevyhoda";


const COMPLETE_CALCULATOR_OF_ADDITIONAL_PARAMS = `&marketingSourceId=${CONTRACT_SOURCE_ID_ZAME}&userGroupId=61`

const ZameBonusPage = props =>
{
  const { data } = props;

  const zamePageData = data.mnd.zame.data.attributes;

  // bonus
  const bonusAmount = zamePageData.bonusAmount;

  // intro banner
  const introBanner = zamePageData.introBanner;
  const introBannerImageURL = `url(${getFullMediaLibraryURL(introBanner.image.data.attributes.url)})`;
  const { title: bannerTitle, text: bannerText, buttonTitle, buttonLink } = introBanner.bannerContent;

  // zpracování hodnot kalkulačky pro lead form
  const {
    onResultChange,
    consumption,
    commodity,
    consumptionHighTariff,
  } = useCalculationResultChange();

  const replaceBonusAmount = text =>
  {
    return text.replace(`{{${BONUS_TEXT_KEY}}}`, formatPrice(bonusAmount));
  };

  // benefits grid
  let benefitsGridData = zamePageData.benefitsGrid;
  benefitsGridData.sections = benefitsGridData.sections.map(section =>
  {
    const transformedTitle = replaceBonusAmount(section.title);

    const transformedPoints = section.points.map(point =>
    {
      return {
        ...point,
        text: replaceBonusAmount(point.text),
      };
    });

    return {
      ...section,
      points: transformedPoints,
      title: transformedTitle,
    };
  });

  // grid doplňujících dat
  let complementaryGridData = zamePageData.complementaryGrid;
  complementaryGridData.sections = complementaryGridData.sections.map(section =>
  {
    const transformedTitle = replaceBonusAmount(section.title);

    const transformedPoints = section.points.map(point =>
    {
      return {
        ...point,
        text: replaceBonusAmount(point.text),
      };
    });

    return {
      ...section,
      points: transformedPoints,
      title: transformedTitle,
    };
  });

  const zameCalculatorData = zamePageData.savingsCalculator;


  const { gasCompetitionOfferCode, eleCompetitionOfferCode } = zameCalculatorData;

  const codesToUse = [
    gasCompetitionOfferCode,
    eleCompetitionOfferCode,
  ]

  // calculator
  const webCalculator = zameCalculatorData;

  const offers = data.mnd.offer.data.attributes.offers.filter(offer => codesToUse.includes(offer.offerCode));

  const calculatorOptions = {
    bonus: {
      isUsingBonus: true,
      priceBonus: bonusAmount,
    },
    form: {
      noCommoditySwitch: false,
      locationRadio: {
        companyDisabled: true,
      },
    },
    result: {
      noResultTypeSwitch: true,
      hideUnderformPDF: false,
      arrangeButton: {
        offerAdditionalParams: COMPLETE_CALCULATOR_OF_ADDITIONAL_PARAMS
      }
    },
    initialData: { selected: CALCULATION_COMMODITY_INDEX.ELECTRICITY },
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Zamevýhoda | MND</title>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.mnd.cz/zamevyhoda"></link>
      </Helmet>

      <MainVizualLP
        title={bannerTitle}
        text={bannerText}
        backgroundImage={introBannerImageURL}
        customImage
        customStyle={{
          wrapper: {
            paddingTop: [0],
            backgroundColor: ["white.100", null, "resourceGreenLighter"],
          },
          heading: {
            mb: ["16px", null, "24px"],
          },
          textWrapper: {
            zIndex: 2,
            px: "0px",
            paddingRight: ["0px", null, "22px", "22px"],
          },
          bannerImage: {
            backgroundPosition: "center bottom",
            backgroundSize: "contain",
            backgroundColor: "resourceGreenLighter",
            height: "500px",
            mx: "15px",
            marginTop: "30px",
            width: "50%",
            display: ["none", null],
          },
          boxContainer: {
            minHeight: [null, null, "396px"],
            px: ["20px", null, "15px"],
          },
          customImage: {
            width: "50%",
          }
        }}
        customButtons={(<Button variant="primary" as="a" href={buttonLink} minWidth={["210px"]}>
          <img src={require("../images/calc.svg").default} width="20px" height="20px" alt="Kalkulačka" className="mb-0 mr-2" />
          <MarkupToHtml>{buttonTitle}</MarkupToHtml>
        </Button>
        )}
      />

      <SectionGrid gridData={benefitsGridData} />

      <SectionGrid gridData={complementaryGridData} alternative />

      <div className="bg-resource-green-100" id="kalkulacka">
        <SavingsCalculation
          offers={offers}
          data={{
            bottomResultLink: "#kontakt",
            bottomResultText: "Více o nabídce",
            ...webCalculator,
          }}
          analyticsData={{
            section: ZAME_ANALYTICS,
          }}
          options={calculatorOptions}
          onResultChange={onResultChange}
        />
      </div>

      <PhoneContactFormWrapper headline="Získejte výhodné energie od MND se slevou 2 000 Kč">
        <ContactForm
          enableCallbackTime={true}
          enableEmail={false}
          type="PAGE_HP"
          commodity={commodity}
          consumption={consumption * 1000}
          consumptionHighTariff={consumptionHighTariff * 1000}
          analytics={{
            type: "Lead",
            text: "Ozveme se vám",
            section: ZAME_ANALYTICS,
            sourceId: CONTRACT_SOURCE_ID_ZAME,
          }}
          leadsource={CONTRACT_SOURCE_ID_ZAME}
        />
      </PhoneContactFormWrapper>
    </MainLayout>
  );
};

export const query = graphql`
  query ZamePage {
    mnd {
      zame {
        data {
          attributes {
            bonusAmount

            # Intro banner component
            introBanner {
              bannerContent {
                title
                text
                buttonTitle
                buttonLink
              }
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }

            # Benefits component
            benefitsGrid {
              title
              sections {
                id
                title
                points {
                  id
                  text
                }
              }

              buttons {
                text
                link
                icon
              }

              pdfs {
                id
                name
                pdfFile {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }

              pdfs2 {
                id
                name
                pdfFile {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }

            # komponenta gridu doplňujících dat
            complementaryGrid {
              title
              sections {
                id
                title
                points {
                  id
                  text
                }
              }
            }

            savingsCalculator {
              gasCompetitionOfferCode
              eleCompetitionOfferCode
            }

            # End of content
          }
        }
      }

      ...FullOffers
    }
  }
`;

export default ZameBonusPage;
