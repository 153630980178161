import React from "react";
import { Heading } from "../../style/Heading";
import { GridContentItem, GridItemChecklist, GridItemChecklistItem, GridItemChecklistItemContent } from "../../style/SectionGrid/SectionGridItem";
import IconCheckCircle from "../SVG/IconCheckCircle";
import { theme } from "../../style/theme";
import { Text } from "../../style/Text";
import { MarkupToHtml } from "../../helpers/parse";
import { ConditionalDisplayWrapper } from "../../style/Responsive/ConditionalDisplay";

const SectionGridItem = ({ item, gridItemsPerRow, alternative }) =>
{
  const isAlternative = alternative || false;

  const pointsJSX = item.points.map(point =>
  {
    return (
      <GridItemChecklistItem key={point.id}>
        <IconCheckCircle fill={theme.colors.resourceGreen} width={16} height={16} fixedWidth marginRight="16px" position="relative" top="5px" />
        <GridItemChecklistItemContent>
          <MarkupToHtml components={{
            p: props => (<Text as="p" fontSize={isAlternative ? "15px" : "18px"} mb={15} lineHeight={"24px"} >{props.children}</Text>),
            a: props => <Text as="a" {...props} fontSize={isAlternative ? "15px" : "18px"} mb={15} lineHeight={"24px"}>{props.children}</Text>,
          }}>{point.text}</MarkupToHtml>
        </GridItemChecklistItemContent>
      </GridItemChecklistItem>
    );
  });

  const hasPoints = pointsJSX.length > 0;

  return (
    <GridContentItem key={item.title} gridItemsPerRow={gridItemsPerRow}>

      <MarkupToHtml components={{
        p: props => (<Text as="p" fontSize={isAlternative ? "15px" : "19px"} mb={15} lineHeight={"24px"} color="expertiseGray">{props.children}</Text>),
        br: props => <ConditionalDisplayWrapper {...props} hideOnBreakpoint="md" as="br" />,
        h3: props => (
          <Heading
            as="h3"
            fontWeight="bold"
            textAlign={["left", null, "center"]}
            fontSize={["22px", null, "26px"]}
            lineHeight={["30px", null, "36px"]}
            color="green.dark"
            marginBottom={["8px", null, "16px"]}
          >
            {props.children}
          </Heading>
        ),
        h4: props => (
          <Heading
            as="h4"
            fontWeight="bold"
            textAlign={["left", null, "left"]}
            fontSize={["12px", null, "15px"]}
            lineHeight={["22px", null, "24px"]}
            color="green.dark"
            marginBottom={["8px", null, "16px"]}
          >
            {props.children}
          </Heading>
        ),
      }}>{item.title}</MarkupToHtml>

      {hasPoints && <GridItemChecklist>{pointsJSX}</GridItemChecklist>}
    </GridContentItem >
  );
};

export default SectionGridItem;
