import styled from "styled-components";

export const StyledSectionGridContent = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.itemsPerRow || 3}, 1fr)`};
  row-gap: 16px;

  width: 100%;

  margin-bottom: 24px;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0px;
  }
`;
