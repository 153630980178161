import styled, { css } from "styled-components";

export const GridContentItem = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 20px;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0px;
    margin: 0 20px;
  }

  // Pro grid itemy, které nejsou poslední v řádku, přidáme pravý border
  // pro větší obrazovky, kde je grid horizontální
  &:not(:nth-child(${props => props.gridItemsPerRow})) {
    border-right: solid 1px ${props => props.theme.colors.grey.standard};

    @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
      border-right: none;
    }
  }

  // Pro grid itemy, které nejsou poslední v gridu, přidáme dolní border
  // pro malé obrazovky, kde je grid vertikální
  &:not(:last-child) {
    @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
      border-bottom: solid 1px ${props => props.theme.colors.grey.standard};
    }
  }
`;

export const GridItemChecklist = styled.ul`
  display: flex;
  flex-direction: column;

  margin: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 24px;
  }

  list-style: none;
`;

export const GridItemChecklistItem = styled.li`
  display: flex;
  align-items: flex-start;

  margin-bottom: 16px;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0px;
  }
`;

export const GridItemChecklistItemContent = styled.p`
  // definování přesunuto do komponenty MarkupToHtml components
  // font-size: 18px;
  // line-height: 24px;
  
  color: ${props => props.theme.colors.grey.dark};
  & strong,
  & b,
  & u,
  & a {
    color: ${props => props.theme.colors.green.dark};
    // konkrétně určeno pro odkazy, override class text-small z MarkupToHtml
    // font-size: 18px !important; - override zde není nutný stačí definovat bloky v MarkupToHtml components

    // aby vzdálenost textu a underline byla stejná nezávisle na pořadí strong a underline
    text-underline-offset: 1px !important;
  }
`;
