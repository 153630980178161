import styled, { css } from "styled-components";

/**
 * Hides the element on the breakpoint and lower
 * (Shows the element on the breakpoint and higher)
 */
export const HideOn = breakpoint => css`
  @media screen and (max-width: ${props => props.theme.breakpoints[breakpoint]}) {
    display: none;
  }
`;

/**
 * Shows the element only on the breakpoint and lower.
 * (Hides the element on the breakpoint and higher)
 */
export const ShowOn = breakpoint => css`
  @media screen and (min-width: ${props => props.theme.breakpoints[breakpoint]}) {
    display: none;
  }
`;

/**
 * You can embed HideOnEmbed in your styled component to hide it on a breakpoint and lower
 */
export const HideOnEmbed = css`
  ${props => (!!props.hideOnBreakpoint ? HideOn(props.hideOnBreakpoint) : ``)}
`;

/**
 * You can embed ShowOnEmbed in your styled component to show it only on a breakpoint and lower
 */
export const ShowOnEmbed = css`
  ${props => (!!props.showOnBreakpoint ? ShowOn(props.showOnBreakpoint) : ``)}
`;

/**
 * You can embed ConditionalDisplay in your styled component to hide or show it on a certain breakpoint
 */
export const ConditionalDisplay = css`
  ${HideOnEmbed}
  ${ShowOnEmbed}
`;

/**
 * You can use ConditionalDisplayWrapper to wrap your component to hide or show it on a certain breakpoint
 */
export const ConditionalDisplayWrapper = styled.div`
  ${ConditionalDisplay}
`;
