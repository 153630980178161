import styled, { css } from "styled-components";
import { margin, position } from "styled-system";

export const FixedWidthCSS = css`
  min-width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};

  min-height: ${props => `${props.height}px`};
  max-width: ${props => `${props.height}px`};
`;

export const StyledSVG = styled.svg`
  ${props => props.fixedWidth && FixedWidthCSS}
  ${position}
  ${margin}
`;
