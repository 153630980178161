import React from "react";
import { StyledSectionGridContent } from "../../style/SectionGrid/SectionGridContent";
import SectionGridItem from "./SectionGridItem";

const MAX_ITEMS_PER_ROW = 3;

const SectionGridContent = ({ gridContent, alternative }) =>
{
  let itemsPerRow = gridContent.length;

  if (itemsPerRow > MAX_ITEMS_PER_ROW)
  {
    itemsPerRow = MAX_ITEMS_PER_ROW;
  }

  const gridContentJSX = gridContent.map(item =>
  {
    return <SectionGridItem key={item.id} item={item} gridItemsPerRow={itemsPerRow} alternative={alternative} />;
  });

  return <StyledSectionGridContent itemsPerRow={itemsPerRow}>{gridContentJSX}</StyledSectionGridContent>;
};

export default SectionGridContent;
