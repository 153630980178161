import React from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import SectionGridContent from "./SectionGridContent";
import { Button } from "../../style/Button";
import { getFullMediaLibraryURL } from "../../helpers/mediaLibrary/mediaLibraryUtils";
import { theme } from "../../style/theme";
import IconPdfDocument from "../SVG/IconPdfDocument";
import { PdfsRowItem } from "../../style/PdfsRow/PdfsRow";

const getButtonIcon = icon =>
{
  switch (icon)
  {
    case "gas":
      return <img src={require("../../images/gas-light.svg").default} height="25px" alt="plyn" className="mb-0 mr-2" />;
      break;
    case "ele":
      return <img src={require("../../images/electricity-light.svg").default} height="25px" alt="elektrika" className="mb-0 mr-2" />;
      break;
    default:
      return null;
      break
  }
}

const SectionGrid = ({ gridData, alternative }) =>
{
  const { title, sections: gridContent, buttons, pdfs, pdfs2 } = gridData;

  const isAlternative = alternative || false;

  const hasPDFs = pdfs && pdfs.length > 0;
  const hasPDFs2 = pdfs2 && pdfs2.length > 0;

  const gasPdfsMapped = hasPDFs && pdfs.map(pdf =>
  {
    return {
      ...pdf,
      fileUrl: getFullMediaLibraryURL(pdf.pdfFile.data.attributes.url),
    };
  })
    || [];

  const elePdfsMapped = hasPDFs2 && pdfs2.map(pdf =>
  {
    return {
      ...pdf,
      fileUrl: getFullMediaLibraryURL(pdf.pdfFile.data.attributes.url),
    };
  })
    || [];


  return (
    <Wrapper backgroundColor={"grey.standard"}>
      <Container px={["0px", null, "15px"]}>
        {title && (isAlternative && (
          <Box backgroundColor="white.100" paddingTop="32px" width="100%" mt={"-50px"} >
            <hr className="m-0 bg-gray border-0" style={{ height: `2px` }} />
            <Heading
              as="h3"
              width="100%"
              fontWeight="bold"
              fontSize={["20px", null, "23px"]}
              lineHeight={["32px", null, "35px"]}
              textAlign="center"
              color="green.dark"
              marginTop="40px"
              marginBottom="20px"
            >
              {title}
            </Heading>
          </Box>
        ) || (
            <Heading
              as="h2"
              width="100%"
              fontWeight="bold"
              fontSize={["26px", null, "36px"]}
              lineHeight={["36px", null, "50px"]}
              textAlign="center"
              color="green.dark"
              marginTop="20px"
              marginBottom="32px"
            >
              {title}
            </Heading>
          ))}
        <Box backgroundColor="white.100" paddingTop="32px" paddingBottom="40px" width="100%">
          <SectionGridContent gridContent={gridContent} alternative={alternative} />

          {buttons?.length && (
            <Flex flexDirection={"row"} mt="10px" justifyContent="center" px={[0, null, "100px", "200px"]} flexWrap="wrap">
              {buttons.map(button => (
                <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
                  <Button as="a" href={button.link} minWidth="220px" mt={["30px", 0]}>
                    {getButtonIcon(button.icon)}
                    {button.text}
                  </Button>
                </Flex>
              ))}
            </Flex>
          )}
          <Flex flexDirection={"row"} mt="25px" justifyContent="center" flexWrap="wrap" px={[0, null, "100px", "200px"]}>
            <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
              <Flex flexDirection={"column"} alignItems={"flex-start"}>
                {gasPdfsMapped.map((pdf, idx) => (
                  <PdfsRowItem href={pdf.fileUrl} target="_blank">
                    <Flex padding={"4px"}>
                      <IconPdfDocument fill={theme.colors.resourceGreen} fixedWidth />
                    </Flex>
                    {pdf.name}
                  </PdfsRowItem>
                ))}
              </Flex>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"center"} margin={"0 auto"} flex={"1"}>
              <Flex flexDirection={"column"} alignItems={"flex-start"}>
                {elePdfsMapped.map((pdf, idx) => (
                  <PdfsRowItem href={pdf.fileUrl} target="_blank">
                    <Flex padding={"4px"}>
                      <IconPdfDocument fill={theme.colors.resourceGreen} fixedWidth />
                    </Flex>
                    {pdf.name}
                  </PdfsRowItem>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Box marginBottom={["0px", null, "48px"]} />
      </Container >
    </Wrapper >
  );
};

export default SectionGrid;
