import styled from "styled-components";

export const PdfsRowItem = styled.a`
  display: flex;
  align-items: center;

  text-decoration: underline;
  color: ${props => props.theme.colors.green.dark};

  font-size: 14px;
`;

export const StyledPdfsRowWrapper = styled.div`
  margin: 0px 16px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 24px;
  padding: 0px 200px;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0;
  }
`;

export const StyledPdfsRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}) {
    gap: 16px;
    flex-direction: column;
  }
`;
